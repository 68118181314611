import React, {useState, useEffect, Fragment } from 'react';
import { ThemeProvider } from 'styled-components';
import { appTheme } from 'common/src/theme/app';
import {
  GlobalStyle,
  AppWrapper, 
  ConditionWrapper
} from '../containers/App/app.style';
import FaqSection from '../containers/App/Faq';
import Sticky from 'react-stickynode';
import Navbar from '../containers/App/Navbar';
import Footer from '../containers/App/Footer';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
// import styled from 'styled-components';
import { ResetCSS } from 'common/src/assets/css/style';
import SEO from '../components/seo';
// import { useLocation } from '@reach/router';
// import queryString from 'query-string';

// const getSelectedTheme = (query) => {
//   const fallback = 'light';

//   if (query) {
//     const queriedTheme = queryString.parse(query);
//     const { theme } = queriedTheme;

//     // Ensure a valid expected value is passed
//     if (['light', 'dark'].includes(theme)) {
//       return theme;
//     }

//     return fallback;
//   }

//   return fallback;
// };

function getSize() {
  return {
    innerHeight: window.innerHeight,
    innerWidth: window.innerWidth,
    outerHeight: window.outerHeight,
    outerWidth: window.outerWidth,
  };
}

function useWindowSize() {
  let [windowSize, setWindowSize] = useState(getSize());

  function handleResize() {
    setWindowSize(getSize());
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return windowSize;
}

export default () => {   
  const size = process.browser && useWindowSize();
  const innerWidth = process.browser && size.innerWidth;
  // const location = useLocation();
  // const theme = (location.search && getSelectedTheme(location.search)) || 'light';  

  return (
    <ThemeProvider theme={appTheme}>
      <Fragment>
        <SEO title="LuzIA | Privacidad" />
         
        <ResetCSS />
        <GlobalStyle />
        <AppWrapper>
        <ConditionWrapper id="topBar">
            {innerWidth > 1100 ? 
            <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
              <DrawerProvider>
                <Navbar />
              </DrawerProvider>
            </Sticky>
            : <div />}
          </ConditionWrapper>          
          <FaqSection />
          <ConditionWrapper id="topBar">
            {innerWidth > 1100 ? <Footer /> : <div />}
          </ConditionWrapper>
        </AppWrapper>
      </Fragment>
    </ThemeProvider>
  );
};
